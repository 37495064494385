import React, { useEffect } from 'react';
import { setCookie } from 'cookies-next';

import { Container } from '@rugby-au/container';
import { ScrollView, View } from 'react-native';
import { SignInLayout } from '@rugby-au/authentication';
// import { WebHeader } from '@rugby-au/web-header';
import { NextPage } from 'next';
import { ClientAuthProps, getClientAuthProps, setItem } from '@rugby-au/services';
import { getThemeColorForSite } from '@rugby-au/theme';

import { siteColors } from '../../configs/siteTheme';
import { PageLayout } from '../../component/PageLayout';

export interface LoginAuthProps extends ClientAuthProps {
  error?: any;
  clientColors?: any;
  loginLayout?: {
    backgroundImage?: string;
    heading?: string;
    subImage?: string;
  };
}

const Login: NextPage<LoginAuthProps> = client => {
  const [isLoading, setIsLoading] = React.useState(true);
  //const { spacing, typography, colors } = useTheme();
  useEffect(() => {
    if (!client || client?.error) {
      window.location.href = client?.redirectUrl ?? '/401';
    }
    if (client?.username === '' && client?.redirectUrl) {
      window.location.href = client.redirectUrl;
    }
    setItem('client', client)
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Login.tsx line 62 - error -> ', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScrollView>
      <PageLayout siteColors={client.clientColors} includeHeader={false} logo={client.clientColors?.logo}>
        <Container>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            {!isLoading && client.username !== '' ? (
              <SignInLayout
                layoutKey={''}
                layoutRef={null}
                layout="column"
                subTextLine1={'New Here?'}
                layoutProps={{
                  backgroundImage: client?.loginLayout?.backgroundImage ?? 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/dcdab150-481c-4165-c97e-08db872b653e.jpeg',
                  heading: client?.loginLayout?.heading ?? 'One account for everything Rugby',
                  subImage: client?.loginLayout?.subImage ?? 'https://d26phqdbpt0w91.cloudfront.net/NonVideo/3f51c965-17cc-4eb3-c97f-08db872b653e.svg',
                }}
                header="Login to Continue"
              />
            ) : null}
          </View>
        </Container>
      </PageLayout>
    </ScrollView>
  );
};

Login.getInitialProps = async ({ req, res, query }) => {
  if (!query.clientId) {
    return { error: {}, redirectUrl: '/400' } as LoginAuthProps;
  }
  if (query.redirectUrl) {
    const redirectUrl = new URL(decodeURIComponent(query.redirectUrl as string));
    query.redirectPath = redirectUrl.pathname + redirectUrl.search;
  }
  const apiToken = await getClientAuthProps({
    username: query.clientId as string,
    redirectPath: query.redirectPath as string,
    codeChallenge: query.codeChallenge as string,
  });
  let clientColors;
  if (apiToken?.auth) {
    //Setting client colors
    clientColors = getThemeColorForSite({ primary: apiToken.auth.primaryColour, secondary: apiToken.auth.secondaryColour }) ?? siteColors;

    //set clientId
    setCookie('clientId', apiToken.username, { req, res });
  } else {
    clientColors = siteColors;
  }

  if (apiToken?.error) {
    if (apiToken.error.status === 503) {
      return { error: apiToken.error, redirectUrl: '/503' } as LoginAuthProps;
    } else if (apiToken.error.status === 400) {
      return { error: apiToken.error, redirectUrl: apiToken.error?.originalError?.redirectUrl ?? '/400' } as LoginAuthProps;
    }
    return { error: apiToken.error, redirectUrl: '/401' } as LoginAuthProps;
  }
  if (!apiToken || !apiToken.username) {
    return { username: '', codeChallenge: '', redirectUrl: '/404' } as LoginAuthProps;
  }
  if (query.redirectPath) {
    apiToken.redirectPath = query.redirectPath;
  }
  // else if (query.redirectUrl) {
  //   // const url = new URL(decodeURIComponent(query.redirectUrl as string));
  //   // apiToken.redirectPath = url.pathname + url.search;
  //   apiToken.redirectUrl = encodeURIComponent(query?.redirectUrl as string);
  // }

  return {
    username: apiToken.username,
    codeChallenge: query.codeChallenge,
    ssoGroup: apiToken.ssoGroup,
    noPkce: apiToken.noPkce,
    redirectPath: apiToken.redirectPath,
    clientColors,
    loginLayout: { backgroundImage: apiToken.auth?.loginBanner, heading: apiToken.auth?.title, subImage: apiToken.auth?.loginForeground },
    SAMLRequest: { request: query.SAMLRequest, relayState: query.RelayState },
  } as LoginAuthProps;
};

export default Login;
